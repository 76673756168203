<template>
	<div id="Cooperation">
		<div id="Cooperation-box">
			Cooperation
		</div>
	</div>
</template>

<script>
	export default{
		name:'Cooperation',
		data(){
			return{
				
			}
		}
	}
</script>

<style lang="scss">
	#Cooperation-box{
		background: red;
	}
</style>
